@import 'src/styles/variables/variables';

@include useTheme();

.Container {
  padding: 58px 20px;
  @include min-width(large-tablet) {
    padding: 70px 131px 75px;
  }
}

.Overline {
  text-align: center;
  @include primaryFont(500);
  letter-spacing: 1px;

  @include min-width(large-tablet) {
    @include primaryFont(500);
  }
}

.QuoteAndLogo {
  min-height: 261px;
  display: flex;
  flex-direction: column;
  justify-content: center;

  @include min-width(small-tablet) {
    min-height: 171px;
  }

  @include min-width(large-tablet) {
    min-height: 223px;
  }

  @include min-width(desktop) {
    align-items: center;
    flex-direction: row;
  }
}

.Quote {
  margin: 21px auto 24px auto;

  @include max-width(desktop) {
    font-size: 24px;
    line-height: 30px;
  }

  @include min-width(large-tablet) {
    margin: 40px auto;
  }
  @include min-width(desktop) {
    width: 841px;
  }
}
.LogoMobile {
  text-align: center;
  @include min-width(desktop) {
    display: none;
  }
}

.Logos {
  display: none;
  align-items: center;
  justify-content: space-between  ;
  column-gap: 25px;
  margin-top: 34px;
  position: unset;

  button {
    display: block;
    outline: none;
    border: none;
    background-color: transparent;
  }

  @include min-width(desktop) {
    display: flex;
  }

}

.IsSelected {
  svg {
    path {
      fill: $cinnamon;
    }
  }
}

.Logos, .LogoMobile {
  svg {
    &:hover {
      path {
        fill: $cinnamon;
      }
    }
    flex-basis: 20%;
    max-height: 25px;

    @include min-width(desktop) {
      max-height: 30px;
      width: 100%;
    }
  }
}

.Pagination {
  display: flex;
  margin-top: 25px;
  @include min-width(desktop) {
    display: none;
  }

  button {
    &:hover {
      color: $core8;
    }
  }
}
